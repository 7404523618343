import React, { Component, Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Tabs, Tab } from 'react-bootstrap';
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

import Joi from 'joi-browser';
import Form from "../common/form";
import DateInput from "../common/dateInput";
import ToggleButton from '../common/toggleButton';
import solicitationService from "../../services/solicitationService";
import CheckInput from "../common/checkInput";

export class SolicitationForm extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                solicitationId: "",
                solicitationTitle: "",
                solicitationNumber: "",
                solicitationStatus: "",
                solicitationNoticeID: "",
                solicitationLink: "",
                solicitationTargetIC: "",
                primaryCs: "",
                noticeTypeId: "",
                solicitationResponseDeadline: new Date(),
                solicitationIsRD: "",
                solicitationEnableOther: "",
                solicitationIsRDOverriden:""

            },
            icList: [],
            cscoList: [],
            noticeTypes: [],
            status: [{ id: "Open", name: "Open" }, { id: "Closed", name: "Closed" }],
            selectedTargetIc: "",
            solicitationFormHeader: "Create Solicitation",
            isEdit: false,
            isTaskOrderSol: false,
            selectedTab: 'solicitationDetails',
            errors: {}
        };

        this.schema = {
            solicitationId: Joi.string().allow('').optional(),
            solicitationTitle: Joi.string().required().error(() => { return { message: "Solicitation Title required." }; }),
            solicitationNumber: Joi.string().required().error(() => { return { message: "Solicitation Number required." }; }),
            solicitationStatus: Joi.string().required().error(() => { return { message: "Status required." }; }),
            solicitationNoticeID: Joi.string().allow(null, ''),
            solicitationLink: Joi.string().allow(null, ''),
            solicitationTargetIC: Joi.string(),
            primaryCs: Joi.number().required().error(() => { return { message: "CS/CO selection required." }; }),
            noticeTypeId: Joi.number(),
            solicitationResponseDeadline: Joi.date().required().error(() => { return { message: "Response Deadline required." }; }),
            solicitationIsRD: Joi.number(),
            solicitationEnableOther: Joi.number(),
            solicitationIsRDOverriden: Joi.number()
        };

        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleToggleButtonChange = this.handleToggleButtonChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.fetchDataForDropdown = this.fetchDataForDropdown.bind(this);
        this.onSolicitationLoaded = this.onSolicitationLoaded.bind(this);
        this.populateSolicitation = this.populateSolicitation.bind(this);
        this.mapToViewModel = this.mapToViewModel.bind(this);
        this.doSubmit = this.doSubmit.bind(this);
        this.editSolicitation = this.editSolicitation.bind(this);
        this.createSolicitation = this.createSolicitation.bind(this);
    }

    


    async fetchDataForDropdown(SolicitationTargetIC) {
        const { data } = await solicitationService.getDropDownListForSolicitation(SolicitationTargetIC);
        //console.log("Solicitation Select List data: ", data);
        const icList = data.icList.map(name => ({ id:name,name }));
        const cscoList = data.csList.map(cs => ({ id: cs.appUserId, name: cs.appUserFullName }));
        const noticeTypes = data.noticeTypes.map(nt => ({ id: nt.noticeTypeId, name: nt.noticeTypeName }));
        this.setState({ icList, cscoList, noticeTypes });
        let stateData = { ...this.state.data }
        //console.log("icList", icList);
        const targetIcSelection = icList.find(x => x.name === this.state.selectedTargetIc);
        const noticeTypeSelection = noticeTypes.find(x => x.name.trim() === "Solicitation");
        //console.log("notice type Selection", noticeTypeSelection);
        //stateData.solicitationTargetIC = icList[0].id;
        stateData.solicitationTargetIC = targetIcSelection.id;
        //stateData.primaryCs = cscoList[0].id;
        //stateData.primaryCs = 1; 
        stateData.solicitationIsRD = 1; //TRUE -default value
        stateData.solicitationEnableOther = 1; //TRUE -default value
        stateData.solicitationIsRDOverriden = 1; //TRUE -default value
        stateData.noticeTypeId = noticeTypeSelection.id;
        stateData.solicitationStatus = this.state.status[0].id;
        this.setState({ data:stateData});
    }

    onSolicitationLoaded(solicitation) {
        this.props.onDataLoaded(solicitation);
    }

    async populateSolicitation() {
        //console.log(this.props);
        const solicitationId = this.props.match.params.id.toString();
        
        if (solicitationId === 'new') return;

        //else Edit
        const { data: solicitation } = await solicitationService.getSolicitation(solicitationId);
        this.setState({ isEdit:true });

        //const solicitationData = this.props.solicitation;
        //console.log("EditSolicitation Data:", solicitation);
        this.onSolicitationLoaded(solicitation);
        this.setState({ data: this.mapToViewModel(solicitation) });


    }
    componentWillReceiveProps(newProps) {
        if (newProps.defaultTabOnSolForm) {
            this.setState({ selectedTab: newProps.defaultTabOnSolForm });
        }
    }
    async componentDidMount() {
        const selectedRoleObj = JSON.parse(localStorage.getItem("selectedIcRole"));
        const selectedTargetIc= selectedRoleObj.icId;
        //console.log("Selected IC Value:", selectedTargetIc);
        this.setState({ selectedTargetIc });
        await this.fetchDataForDropdown(selectedTargetIc);
        await this.populateSolicitation();
    }

    mapToViewModel(solicitation) {
        return {
            solicitationId: solicitation.solicitationID.toString(),
            solicitationTitle: solicitation.solicitationTitle,
            solicitationNumber: solicitation.solicitationNumber,
            solicitationStatus: solicitation.solicitationStatus,
            solicitationNoticeID: solicitation.solicitationNoticeID,
            solicitationLink: solicitation.solicitationLink,
            solicitationTargetIC: solicitation.solicitationTargetIC,
            primaryCs: solicitation.primaryCs,
            noticeTypeId: solicitation.noticeTypeId,
            solicitationResponseDeadline: solicitation.solicitationResponseDeadline,
            solicitationIsRD: solicitation.solicitationIsRD ? 1: 0,
            solicitationEnableOther: solicitation.solicitationEnableOther ? 1 : 0,
            solicitationIsRDOverriden: solicitation.solicitationIsRDOverriden ? 1 : 0
        };
    }
    handleCheckChange(name, value) {
      const data = Object.assign({}, this.state.data);
      data['isChecked'] = value;

      this.setState({data});
    }
    handleToggleButtonChange(name, value) {
        const data = Object.assign({}, this.state.data);
        data[name] = value === "Yes" ? 1 : 0;

        this.setState({ data});
    }
    handleDateChange(input) {
        //console.log("Handle Date change", input);
        //console.log(name + ' ' + value);
        //const data = Object.assign({}, this.state.data);
        //data[name] = value === "True" ? 1 : 0;
        //this.setState({ data });;
    }
    doSubmit = async () => {
        //console.log("Submitting Solicitation Creation Form");
        if (!this.state.isEdit) {
            await this.createSolicitation();
        } else {
            await this.editSolicitation();
        }
    };
    
    async editSolicitation() {
        const solId = this.state.data.solicitationId;
        try{
            
            const { data, status } = await solicitationService.editSolicitation(this.state.selectedTargetIc, this.state.data);
            
            if (status === 200) {
                toast.success("Solicitation Updated Successfully");
               
                this.redirectToEditPage(solId);
            } else {
                toast.error("Error in Solicitation Update");
                this.redirectToEditPage(solId);
            }

        } 
        catch (ex) {
            toast.error("Error Updating Solicitation, please try again");
            this.redirectToEditPage(solId);
        } 
        
    }

    async createSolicitation() {
        try{
            var result = await solicitationService.saveSolicitation(this.state.selectedTargetIc, this.state.data);
            const solId = result.data;
            const status = result.status;
            if (status === 200) {
               toast.success("Solicitation Created Successfully");
               this.redirectToEditPage(solId);
            };
        }
        catch (ex) {
            //console.log("Error happened in Create/edit: ", ex);
            toast.error("Error Creating Solicitation, please try again");
            setTimeout(() => {this.props.history.push("/solicitation/new");window.location.reload();}, 2000);
        } 
        
    }

    redirectToEditPage(solId) {
        setTimeout(() => {
            this.props.history.push(`/solicitation/${solId}`);
            window.location.reload();
        },
            2000);
    }

    render() { 
        
        return ( 
                <Fragment>
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                        <div className="section-heading">{this.state.isEdit ? "Edit Solicitation" : "Create Solicitation"}</div>

                        {/*<div>
                                    <nav className="internal-page-tabs">
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <a className="nav-item nav-link " id="solic-details-tab" data-toggle="tab" href="#solic-details" role="tab" aria-controls="solic-details" aria-selected="true">Solicitation Details</a>
                                        <a className="nav-item nav-link active" id="manage-vendors-tab" data-toggle="tab" href="#manage-vendors" role="tab" aria-controls="manage-vendors" aria-selected="false">Manage Vendors <span className="count-icon">4</span></a>
                                            <a className="nav-item nav-link" id="manage-staff-tab" data-toggle="tab" href="#manage-staff" role="tab" aria-controls="nav-contact" aria-selected="false">Manage Staff Users <span className="count-icon">1</span></a>
                                        </div>
                                    </nav>

                                    <div className="tab-content internal-page-tabs">
                                
                                
                                
                            </div>
                            */
                            }

                            <div className="internal-page-tabs tab-content">
                            <Tabs defaultActiveKey="solicitationDetails" activeKey={this.state.selectedTab} onSelect={(eventKey, event) => { this.setState({ selectedTab: eventKey})}} transition={false} id="solTab">
                                    <Tab eventKey="solicitationDetails" title="SOLICITATION DETAILS">
                                        <div  id="solic-details" role="tabpanel" aria-labelledby="solic-details-tab">
                                            <div className="tile-section form-section edit-solicitation">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-row">
                                                        <div className="col-lg-3">
                                                            
                                                            <ToggleButton
                                                                name="solicitationType"
                                                                label="Solicitation Type"
                                                            options={['Base', 'Task Order']}
                                                            value="Base"
                                                                handleChange={this.handleToggleButtonChange}
                                                                widthClass="toggle-med"
                                                            />
                                                        </div>
                                                        
                                                         {this.state.isTaskOrderSol && this.renderInput("contractTitle","Initial IDIQ/Base Contract Title","col-lg-9","text",false)}
                                                    </div>
                                                    <div className="form-row">
                                                       
                                                    {this.renderTextArea("solicitationTitle", "Solicitation Title")}
                                                    {/* {this.renderInput("solicitationTitle", "Solicitation Title", "col-lg-9", "text")} */}
                                                    {this.renderInput("solicitationNumber", "Solicitation Number", "col-lg-3", "text")}
                                                    </div>
                                                    <div className="form-row">
                                                    {this.renderSelect("solicitationTargetIC", "Target IC", this.state.icList)}
                                                    {this.renderDateInput("solicitationResponseDeadline", "Response Deadline",this.state.data.solicitationResponseDeadline)}
                                                    {this.renderSelect("primaryCs", "CS/CO", this.state.cscoList,true)}
                                                    {this.renderSelect("noticeTypeId", "Notice Type", this.state.noticeTypes)}
                                                    </div>
                                                    <div className="form-row">
                                                        {this.renderSelect("solicitationStatus", "Status", this.state.status)}
                                                        <div className="col-lg-3 col-md-6">
                                                            <ToggleButton
                                                                name="solicitationIsRD"
                                                                label=" Separation of Technical and Business Proposals"
                                                                options={['Yes','No']}
                                                                value={this.state.data.solicitationIsRD == 1? "Yes" : "No"}
                                                                handleChange={this.handleToggleButtonChange}
                                                                widthClass="toggle-small"
                                                            />
                                                            
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                          <ToggleButton
                                                                name="solicitationEnableOther"
                                                                label="Enable PHS Human Subjects and Clinical Trials Document Submission"
                                                                options={['Yes','No']}
                                                                value={this.state.data.solicitationEnableOther  == 1? "Yes" : "No"}
                                                                handleChange={this.handleToggleButtonChange}
                                                                widthClass="toggle-small"
                                                          />
                                                            
                                                        </div>
                                                    {!this.state.isTaskOrderSol && (
                                                        <div className="col-lg-3 col-md-6">
                                                            <ToggleButton
                                                                name="solicitationIsRDOverriden"
                                                                label="Exclude this solicitation from sync"
                                                                options={['Yes', 'No']}
                                                                value={this.state.data.solicitationIsRDOverriden == 1 ? "Yes" : "No"}
                                                                handleChange={this.handleToggleButtonChange}
                                                                widthClass="toggle-small"
                                                            />

                                                        </div>
                                                        )}

                                                    </div>
                                                    <div className="form-row">
                                                        {this.renderInput("solicitationNoticeID", "Solicitation Notice ID", "col-lg-3 col-md-6", "text",false)}
                                                        {this.renderInput("solicitationLink", "Solicitation Link", "col-lg-6", "text",false)}
                                                    </div>
                                                    { this.state.isTaskOrderSol && 
                                                    (<div className="form-row">
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="MuiFormControl-root MuiTextField-root">
                                                                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" htmlFor="target" id="task-area-label">
                                                                    Task Area
                                                            </label>
                                                                <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                    <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="task-area">
                                                                        <option value="" selected>Task Area H</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                    </select>
                                                                    <img src="images/icon-arrow-down-light.svg" className="select-down-arrow" alt="select arrow" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="MuiFormControl-root MuiTextField-root">
                                                                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="title" id="task-name-label">
                                                                    Task Area Name
                                                            </label>
                                                                <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                    <input aria-invalid="false" id="task-name" type="text" className="MuiInputBase-input MuiInput-input" value="Bio-Hazards" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)}

                                                    <div className="tile-footer">
                                                            <button className="MuiButtonBase-root MuiButton-root disabled-btn MuiButton-contained med-btn-primary" tabIndex="0" type="submit">
                                                                <span className="MuiButton-label">
                                                                    Save
                                                            </span>
                                                                <span className="MuiTouchRipple-root"></span>
                                                            </button>
                                                            <button className="MuiButtonBase-root MuiButton-root btn-secondary" tabIndex="0" type="button">
                                                                <span className="MuiButton-label">
                                                                    Cancel
                                                            </span>
                                                                <span className="MuiTouchRipple-root"></span>
                                                            </button>
                                                    </div>
                                                </form>

                                                
                                            </div>
                                        </div>
                                    </Tab>
                                {this.state.isTaskOrderSol && (
                                    <Tab eventKey="manageVendors" title="MANAGE VENDORS">
                                        <div  id="manage-vendors" role="tabpanel" aria-labelledby="manage-vendors-tab">
                                            <div className="tile-section form-section edit-solicitation">
                                                <div className="section-heading">Vendors</div>
                                                <div className="above-tab-actions">
                                                    <div className="above-actions">
                                                        <button className="btn-primary MuiButton-root MuiButtonBase-root" type="button" data-toggle="modal" data-target="#invite-vendor-modal"><img src="images/send.svg" alt="icon invite" />Invite Vendors</button>
                                <button className="btn-primary MuiButton-root MuiButtonBase-root"><img src="images/plus-white.svg" alt="add-vendor" />Add Vendor</button>
                                                    </div>
                                                </div>
                                                <div className="accordion" id="vendor-accordion">
                                                    <div className="card">
                                                        <div className="card-header" id="headingOne">
                                                            <div className="row">
                                                                <div className="col-lg-4"><div className="vendor vertical-center">IIT Research Institute</div></div>
                                                                <div className="col-lg-4"><div className="vendor-id vertical-center">75N93019D787B766</div></div>
                                                                <div className="col-lg-4">
                                                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#vendor-1" aria-expanded="true" aria-controls="vendor-1">
                                                                        <div className="link-text">
                                                                            <p>Invite sent</p>
                                                                            <p><small>8/1/20 4:12 PM ET</small></p>
                                                                        </div>
                                                                        <img src="images/icon-arrow-right-blue.svg" alt="icon arrow" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div id="vendor-1" className="collapse show" aria-labelledby="headingOne" data-parent="#vendor-accordion">
                                                            <div className="card-body">
                                                                <div className="MuiTableContainer-root">
                                                                    <table className="MuiTable-root jss190" aria-label="customized table">
                                                                        <thead className="MuiTableHead-root headless-table">
                                                                            <tr className="MuiTableRow-root MuiTableRow-head">
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Name</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Position</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Contact</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Actions</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="MuiTableBody-root">
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Carla Williams</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Business Representative</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">c.williams@iitresearch.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Bernadette Rostenkowski</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Primary Investigator</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">b.rostenkowski@iitresearch.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon"/>Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Leonard Hofstadter</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Other</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">l.hofstadler@iitresearch.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root editable-row">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="name-inputs">
                                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="first-name-label">
                                                                                                First Name
                                                                                    </label>
                                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                                <input aria-invalid="false" id="first-name" type="text" className="MuiInputBase-input MuiInput-input" value="Frederick" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="last-name-label">
                                                                                                Last Name
                                                                                    </label>
                                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                                <input aria-invalid="false" id="last-name" type="text" className="MuiInputBase-input MuiInput-input" value="Flinstone" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="role" id="role-label">
                                                                                            Role
                                                                                </label>
                                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                            <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="role">
                                                                                                <option value="" selected>Other</option>
                                                                                                <option value="1">1</option>
                                                                                                <option value="2">2</option>
                                                                                                <option value="3">3</option>
                                                                                                <option value="4">4</option>
                                                                                            </select>
                                                                                            <img src="images/icon-arrow-down-light.svg" className="select-down-arrow" alt="select arrow" />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="email" id="email-label">
                                                                                            Email
                                                                                </label>
                                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                            <input aria-invalid="false" id="email" type="text" className="MuiInputBase-input MuiInput-input" value="f.flinstone@iitresearch.com" />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root MuiButtonBase-root"><img src="images/icon-no-gray.svg" className="cancel-icon" alt="cancel icon" />Cancel</button>
                                                                                        <button className="btn-primary MuiButton-root MuiButtonBase-root"><img src="images/icon-check-white.svg" alt="save icon" />Save</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-header" id="headingTwo">
                                                            <div className="row">
                                                                <div className="col-lg-4"><div className="vendor vertical-center">Lovelace Biomedical & Environmental Resources</div></div>
                                                                <div className="col-lg-4"><div className="vendor-id vertical-center">75N93019D787B766</div></div>
                                                                <div className="col-lg-4">
                                                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#vendor-2" aria-expanded="false" aria-controls="vendor-2">
                                                                        <div className="link-text">
                                                                            <p>Invite sent</p>
                                                                            <p><small>8/1/20 4:12 PM ET</small></p>
                                                                        </div>
                                                                        <img src="images/icon-arrow-right-blue.svg" alt="arrow icon" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="vendor-2" className="collapse" aria-labelledby="headingTwo" data-parent="#vendor-accordion">
                                                            <div className="card-body">
                                                                <div className="MuiTableContainer-root">
                                                                    <table className="MuiTable-root jss190" aria-label="customized table">
                                                                        <thead className="MuiTableHead-root headless-table">
                                                                            <tr className="MuiTableRow-root MuiTableRow-head">
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Name</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Position</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Contact</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Actions</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="MuiTableBody-root">
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Carla Williams</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Business Representative</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">c.williams@lovelace.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Bernadette Rostenkowski</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Primary Investigator</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">b.rostenkowski@lovelace.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Leonard Hofstadter</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Other</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">l.hofstadler@lovelace.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon"/>Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root editable-row">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="name-inputs">
                                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="first-name2-label">
                                                                                                First Name
                                                                                    </label>
                                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                                <input aria-invalid="false" id="first-name2" type="text" className="MuiInputBase-input MuiInput-input" value="Frederick" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="last-name2-label">
                                                                                                Last Name
                                                                                    </label>
                                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                                <input aria-invalid="false" id="last-name2" type="text" className="MuiInputBase-input MuiInput-input" value="Flinstone" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="role" id="role2-label">
                                                                                            Role
                                                                                </label>
                                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                            <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="role2">
                                                                                                <option value="" selected>Other</option>
                                                                                                <option value="1">1</option>
                                                                                                <option value="2">2</option>
                                                                                                <option value="3">3</option>
                                                                                                <option value="4">4</option>
                                                                                            </select>
                                                                                            <img src="images/icon-arrow-down-light.svg" className="select-down-arrow" alt="select arrow" />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="email" id="email2-label">
                                                                                            Email
                                                                                </label>
                                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                            <input aria-invalid="false" id="email2" type="text" className="MuiInputBase-input MuiInput-input" value="f.flinstone@iitresearch.com" />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root MuiButtonBase-root"><img src="images/icon-no-gray.svg" className="cancel-icon" alt="cancel icon" />Cancel</button>
                                                                                        <button className="btn-primary MuiButton-root MuiButtonBase-root"><img src="images/icon-check-white.svg" alt="save icon" />Save</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-header" id="headingThree">
                                                            <div className="row">
                                                                <div className="col-lg-4"><div className="vendor vertical-center">Moncton Laboratories</div></div>
                                                                <div className="col-lg-4"><div className="vendor-id vertical-center">75N93019D787B766</div></div>
                                                                <div className="col-lg-4">
                                                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#vendor-3" aria-expanded="false" aria-controls="vendor-3">
                                                                        <div className="link-text">
                                                                            <p>Invite sent</p>
                                                                            <p><small>8/1/20 4:12 PM ET</small></p>
                                                                        </div>
                                                                        <img src="images/icon-arrow-right-blue.svg" alt="arrow icon" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="vendor-3" className="collapse" aria-labelledby="headingThree" data-parent="#vendor-accordion">
                                                            <div className="card-body">
                                                                <div className="MuiTableContainer-root">
                                                                    <table className="MuiTable-root jss190" aria-label="customized table">
                                                                        <thead className="MuiTableHead-root headless-table">
                                                                            <tr className="MuiTableRow-root MuiTableRow-head">
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Name</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Position</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Contact</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Actions</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="MuiTableBody-root">
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Carla Williams</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Business Representative</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">c.williams@moncton.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Bernadette Rostenkowski</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Primary Investigator</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">b.rostenkowski@moncton.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Leonard Hofstadter</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Other</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">l.hofstadler@moncton.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root editable-row">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="name-inputs">
                                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="first-name3-label">
                                                                                                First Name
                                                                                    </label>
                                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                                <input aria-invalid="false" id="first-name3" type="text" className="MuiInputBase-input MuiInput-input" value="Frederick" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="last-name3-label">
                                                                                                Last Name
                                                                                    </label>
                                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                                <input aria-invalid="false" id="last-name3" type="text" className="MuiInputBase-input MuiInput-input" value="Flinstone" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="role" id="role3-label">
                                                                                            Role
                                                                                </label>
                                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                            <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="role3">
                                                                                                <option value="" selected>Other</option>
                                                                                                <option value="1">1</option>
                                                                                                <option value="2">2</option>
                                                                                                <option value="3">3</option>
                                                                                                <option value="4">4</option>
                                                                                            </select>
                                                                                            <img src="images/icon-arrow-down-light.svg" className="select-down-arrow" alt="select arrow" />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="email" id="email3-label">
                                                                                            Email
                                                                                </label>
                                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                            <input aria-invalid="false" id="email3" type="text" className="MuiInputBase-input MuiInput-input" value="f.flinstone@iitresearch.com" />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root MuiButtonBase-root"><img src="images/icon-no-gray.svg" className="cancel-icon" alt="cancel icon" />Cancel</button>
                                                                                        <button className="btn-primary MuiButton-root MuiButtonBase-root"><img src="images/icon-check-white.svg" alt="save icon" />Save</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-header" id="headingFour">
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="vendor vertical-center">Wayne Enterprises</div>
                                                                </div>
                                                                <div className="col-lg-4"><div className="vendor-id vertical-center">75N93019D787B766</div></div>
                                                                <div className="col-lg-4">
                                                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#vendor-4" aria-expanded="false" aria-controls="vendor-4">
                                                                        <div className="link-text">
                                                                            <p className="warning-text">Invite note sent</p>
                                                                        </div>
                                                                        <img src="images/icon-arrow-right-blue.svg" alt="arrow icon" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="vendor-4" className="collapse" aria-labelledby="headingFour" data-parent="#vendor-accordion">
                                                            <div className="card-body">
                                                                <div className="MuiTableContainer-root">
                                                                    <table className="MuiTable-root jss190" aria-label="customized table">
                                                                        <thead className="MuiTableHead-root headless-table">
                                                                            <tr className="MuiTableRow-root MuiTableRow-head">
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Name</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Position</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Contact</th>
                                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Actions</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="MuiTableBody-root">
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Carla Williams</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Business Representative</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">c.williams@wayne.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Bernadette Rostenkowski</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Primary Investigator</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">b.rostenkowski@wayne.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight"><a href="#">Leonard Hofstadter</a></td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">Other</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">l.hofstadler@wayne.com</td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="MuiTableRow-root editable-row">
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="name-inputs">
                                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="first-name4-label">
                                                                                                First Name
                                                                                    </label>
                                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                                <input aria-invalid="false" id="first-name4" type="text" className="MuiInputBase-input MuiInput-input" value="Frederick" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="last-name4-label">
                                                                                                Last Name
                                                                                    </label>
                                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                                <input aria-invalid="false" id="last-name4" type="text" className="MuiInputBase-input MuiInput-input" value="Flinstone" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="role" id="role4-label">
                                                                                            Role
                                                                                </label>
                                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                            <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="role4">
                                                                                                <option value="" selected>Other</option>
                                                                                                <option value="1">1</option>
                                                                                                <option value="2">2</option>
                                                                                                <option value="3">3</option>
                                                                                                <option value="4">4</option>
                                                                                            </select>
                                                                                            <img src="images/icon-arrow-down-light.svg" className="select-down-arrow" alt="select arrow" />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="email" id="email4-label">
                                                                                            Email
                                                                                </label>
                                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                            <input aria-invalid="false" id="email4" type="text" className="MuiInputBase-input MuiInput-input" value="f.flinstone@iitresearch.com" />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                                    <div className="td-buttons">
                                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root MuiButtonBase-root"><img src="images/icon-no-gray.svg" className="cancel-icon" alt="cancel icon" />Cancel</button>
                                                                                        <button className="btn-primary MuiButton-root MuiButtonBase-root"><img src="images/icon-check-white.svg" alt="save icon" />Save</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tile-footer">
                                                    <button className="MuiButtonBase-root MuiButton-root disabled-btn MuiButton-contained med-btn-primary" tabIndex="0" type="button">
                                                        <span className="MuiButton-label">
                                                            Save
                                                </span>
                                                        <span className="MuiTouchRipple-root"></span>
                                                    </button>
                                                    <button className="MuiButtonBase-root MuiButton-root btn-secondary" tabIndex="0" type="button">
                                                        <span className="MuiButton-label">
                                                            Cancel
                                                </span>
                                                        <span className="MuiTouchRipple-root"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                     )}
                                     {this.state.isEdit &&  (<Tab eventKey="manageStaff" title="MANAGE STAFF USERS" >
                                        <div  id="manage-staff" role="tabpanel" aria-labelledby="manage-staff">
                                            <div className="tile-section">
                                                <div className="section-heading">Staff Users</div>
                                                <div className="above-tab-actions">
                                                    <div className="above-actions">
                              <button className="MuiButton-root MuiButtonBase-root btn-primary" type="button" data-toggle="modal" data-target="#add-user-modal" onClick={() => this.setState({ defaultTab: 'solicitationDetails' })}><img src="images/plus-white.svg" alt="add-user" />Add User</button>
                                                    </div>
                                                </div>
                                                <div className="white-bordered-rows">
                                                    <table className="MuiTable-root jss190" aria-label="customized table">
                                                        <thead className="MuiTableHead-root headless-table">
                                                            <tr className="MuiTableRow-root MuiTableRow-head">
                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Name</th>
                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Role</th>
                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Document Permissions</th>
                                                                <th className="MuiTableCell-root MuiTableCell-head jss191 MuiTableCell-alignRight" scope="col">Actions</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="MuiTableBody-root">
                                                            <tr className="MuiTableRow-root">
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                    <div className="user-data">
                                                                        <div className="user-fullName">Sheldon Cooper</div>
                                                                        <div className="user-email"><img src="images/envelope.svg" alt="email icon" />sheldon.cooper@nih.gov</div>
                                                                    </div>
                                                                </td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                    <div className="white-table-label">Role</div>
                                                                    <p>Contract Specialist/Officer</p>
                                                                </td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight doc-permissions-td">
                                                                    <div className="white-table-label">Document Permissions</div>
                                                                    <div className="documents-perm">
                                                                        <div className="doc-perm"><img src="images/status-complete.svg" alt="tech pdf icon" />Technical PDF</div>
                                                                        <div className="doc-perm"><img src="images/status-complete.svg" alt="business pdf icon" />Business PDF</div>
                                                                        <div className="doc-perm"><img src="images/error-red.svg" alt="business excel icon" />Business Excel</div>
                                                                    </div>
                                                                </td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                    <div className="td-buttons">
                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/icon-edit-gray.svg" alt="edit icon" />Edit</button>
                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root"><img src="images/trash-gray.svg" alt="delete icon" />Delete</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr className="MuiTableRow-root editable-row">
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                    <div className="user-data">
                                                                        <div className="user-fullName">
                                                                            <div className="MuiFormControl-root MuiTextField-root">
                                                                                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="first-name5-label">
                                                                                    First Name
                                                                        </label>
                                                                                <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                    <input aria-invalid="false" id="first-name5" type="text" className="MuiInputBase-input MuiInput-input" value="Sheldon" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="MuiFormControl-root MuiTextField-root">
                                                                                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required sr-only" data-shrink="true" htmlFor="type" id="last-name5-label">
                                                                                    Last Name
                                                                        </label>
                                                                                <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                    <input aria-invalid="false" id="last-name5" type="text" className="MuiInputBase-input MuiInput-input" value="Cooper" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="user-email"><img src="images/envelope.svg" alt="email icon" />sheldon.cooper@nih.gov</div>
                                                                    </div>
                                                                </td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight">
                                                                    <div className="white-table-label">Role</div>
                                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled sr-only" data-shrink="true" htmlFor="role5" id="role5-label">
                                                                            Role
                                                                </label>
                                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                            <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="role5">
                                                                                <option value="" selected>Contract Specialist/Officer</option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                                <option value="4">4</option>
                                                                            </select>
                                                                            <img src="images/icon-arrow-down-light.svg" className="select-down-arrow" alt="select arrow" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight doc-permissions-td">
                                                                    <div className="white-table-label">Document Permissions</div>
                                                                    <div className="documents-perm">
                                                                      <CheckInput
                                                                        name="technicalPdf"
                                                                        label="Technical PDF"
                                                                        isChecked={this.props.isChecked}
                                                                        handleChange={this.handleCheckChange}
                                                                        className="doc-perm" 
                                                                      />
                                                                      <CheckInput
                                                                        name="businessPdf"
                                                                        label="Business PDF"
                                                                        isChecked={this.props.isChecked}
                                                                        handleChange={this.handleCheckChange}
                                                                        className="doc-perm" 
                                                                      />
                                                                      <CheckInput
                                                                        name="technicalPdf"
                                                                        label="Business Excel"
                                                                        isChecked={this.props.isChecked}
                                                                        handleChange={this.handleCheckChange}
                                                                        className="doc-perm" 
                                                                      />
                                                                    </div>
                                                                </td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight action-td">
                                                                    <div className="td-buttons">
                                                                        <button className="btn-white MuiButtonBase-root MuiButton-root MuiButtonBase-root"><img src="images/icon-no-gray.svg" className="cancel-icon" alt="cancel icon" />Cancel</button>
                                                                        <button className="btn-primary MuiButton-root MuiButtonBase-root"><img src="images/icon-check-white.svg" alt="save icon" />Save</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="tile-footer">
                                                    <button className="MuiButtonBase-root MuiButton-root disabled-btn MuiButton-contained med-btn-primary" tabIndex="0" type="button">
                                                        <span className="MuiButton-label">
                                                            Save
                                                </span>
                                                        <span className="MuiTouchRipple-root"></span>
                                                    </button>
                                                    <button className="MuiButtonBase-root MuiButton-root btn-secondary" tabIndex="0" type="button">
                                                        <span className="MuiButton-label">
                                                            Cancel
                                                </span>
                                                        <span className="MuiTouchRipple-root"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                     )}
                                </Tabs>
                            </div>
                        </div> 

                            
                        
                        </div>
                   

                    <div className="modal fade" id="invite-vendor-modal" tabIndex="-1" role="dialog" aria-labelledby="invite-vendor-modal" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="invite-vendor-modal-label">Confirm Invitation to Vendor</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div className="modal-subtitle">You are about to send a <strong>Vendor Invitation to Participate</strong> for:</div>
                                    </div>
                                    <div className="modal-body">
                                        <div className="modal-message-form">
                                            <form>
                                                <div className="form-row">
                                                    <div className="col-lg-4">
                                                        Task Order Solicitation Title
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <div className="medium-text">Nonclinical Services for Development of Interventional Agents for Infectious Diseases</div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-lg-4">
                                                        Invited Vendors
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <div className="medium-text">Moncton Laboratories <img src="./images/icon-arrow-right-gray.svg" alt="arrow icon"/></div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-lg-12">
                                                        <div className="MuiFormControl-root MuiTextField-root white-input">
                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true">
                                                                Subject
                                                            </label>
                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                <input aria-invalid="false" id="vendor-subject" required="" type="text" className="MuiInputBase-input MuiInput-input" value="Task Order Request <Solicitation Number - Solicitation Title>"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-lg-12">
                                                        <div className="MuiFormControl-root MuiTextField-root white-input">
                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBaseControl MuiInputControl MuiInputBase-multiline MuiInput-multiline">
                                                                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="solic-name" id="modal-message-label">
                                                                    Message
                                                                </label>
                                                                <textarea aria-invalid="false" id="modal-message" rows="5" className="MuiInputBase-input MuiInput-input MuiInputBase-inputMultiline MuiInput-inputMultiline"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="message-submit">
                                                <div className="row">
                                                    <div className="col-lg-2">Documents</div>
                                                    <div className="col-lg-10"><button className="btn-modal-doc MuiButton-root MuiButtonBase-root">
                            <img src="images/plus-white.svg" alt="add-additional-documents"/>Add Documents</button>
                                                        <p className="italic-text">File types allowed include Word (.doc, .docx), Excel (.xls, .xlsx), and PDF (.pdf).</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form>
                                            <div className="form-row">
                                                <div className="col-lg-4">
                                                  <ToggleButton
                                                    name="solicitationType"
                                                    label="Send copy of notification to myself"
                                                    options={['Yes', 'No']}
                                                    handleChange={this.handleToggleButtonChange}
                                                    widthClass="toggle-small"
                                                />
                                                    {/* <fieldset className="MuiFormControl-root toggle-switch toggle-small">
                                                        <legend className="MuiFormLabel-root">
                                                            Send copy of notification to myself
                                                        </legend>
                                                        <div className="MuiFormGroup-root" role="radiogroup" aria-label="investigation">
                                                            <label className="MuiFormControlLabel-root">
                                                                <span className="MuiButtonBase-root MuiIconButton-root jss134 MuiRadio-root MuiRadio-colorSecondary jss135 Mui-checked MuiIconButton-colorSecondary" aria-disabled="false">
                                                                    <span className="MuiIconButton-label">
                                                                        <input className="jss137" name="investigation1" type="radio" value="Yes" checked=""/>
                                                                    </span>
                                                                    <span className="MuiTouchRipple-root"></span>
                                                                </span>
                                                                <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Yes</span>
                                                            </label>
                                                            <label className="MuiFormControlLabel-root">
                                                                <span className="MuiButtonBase-root MuiIconButton-root jss134 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary" aria-disabled="false">
                                                                    <span className="MuiIconButton-label">
                                                                        <input className="jss137" name="investigation1" type="radio" value="No"/>
                                                                    </span>
                                                                    <span className="MuiTouchRipple-root"></span>
                                                                </span>
                                                                <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">No</span>
                                                            </label>
                                                        </div>
                                                    </fieldset> */}
                                                </div>
                                                <div className="col-lg-8">
                                                    <ToggleButton
                                                        name="acknowledgeMessage"
                                                        label=" I acknowledge that I am sending a <strong>Vendor Invitation to Participate</strong> message. It cannot be undone."
                                                        options={['Yes', 'No']}
                                                        handleChange={this.handleToggleButtonChange}
                                                        widthClass=""
                                                    />
                                                    {/* <fieldset className="MuiFormControl-root toggle-switch toggle-small">
                                                        <legend className="MuiFormLabel-root warning-text">
                                                            I acknowledge that I am sending a <strong>Vendor Invitation to Participate</strong> message. It cannot be undone.
                                                        </legend>
                                                        <div className="MuiFormGroup-root" role="radiogroup" aria-label="investigation">
                                                            <label className="MuiFormControlLabel-root">
                                                                <span className="MuiButtonBase-root MuiIconButton-root jss134 MuiRadio-root MuiRadio-colorSecondary jss135 Mui-checked MuiIconButton-colorSecondary" aria-disabled="false">
                                                                    <span className="MuiIconButton-label">
                                                                        <input className="jss137" name="investigation1" type="radio" value="Yes" checked=""/>
                                                                    </span>
                                                                    <span className="MuiTouchRipple-root"></span>
                                                                </span>
                                                                <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Yes</span>
                                                            </label>
                                                            <label className="MuiFormControlLabel-root">
                                                                <span className="MuiButtonBase-root MuiIconButton-root jss134 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary" aria-disabled="false">
                                                                    <span className="MuiIconButton-label">
                                                                        <input className="jss137" name="investigation1" type="radio" value="No"/>
                                                                    </span>
                                                                    <span className="MuiTouchRipple-root"></span>
                                                                </span>
                                                                <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">No</span>
                                                            </label>
                                                        </div>
                                                    </fieldset> */}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                <button type="button" className="MuiButtonBase-root MuiButton-root btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" className="MuiButtonBase-root MuiButton-root disabled-btn MuiButton-contained btn btn-primary">Save, Don't Send and Return to List</button>
                <button type="button" className="MuiButtonBase-root MuiButton-root disabled-btn MuiButton-contained btn btn-primary">Send Invite</button>
              </div>
                                </div>
                        </div>
                    </div>

                    <div className="modal fade" id="add-user-modal" tabIndex="-1" role="dialog" aria-labelledby="add-user-modal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="add-user-modal-label">Add Staff User</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="MuiFormControl-root MuiTextField-root">
                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true">
                                            Name
                                        </label>
                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                            <input aria-invalid="false" id="search-modal" required="" type="text" className="MuiInputBase-input MuiInput-input" value="Cooper, Sheldon"/>
                                        </div>
                                        <i className="search-icon"></i>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="MuiButtonBase-root MuiButton-root btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" className="MuiButtonBase-root MuiButton-root disabled-btn MuiButton-contained btn btn-primary" data-toggle="modal" data-target="#edit-user-modal">Add User</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit-user-modal" tabIndex="-1" role="dialog" aria-labelledby="edit-user-modal" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="edit-user-modal-label">Add Staff User</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form className="form-row">
                                    <div className="col-lg-6">
                                        <div className="MuiFormControl-root MuiTextField-root">
                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true">
                                                Name
                                            </label>
                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                <input aria-invalid="false" id="search-edit-modal" required="" type="text" className="MuiInputBase-input MuiInput-input" value="Cooper, Sheldon"/>
                                            </div>
                                            <i className="search-icon"></i>
                                        </div>
                                    </div>
                                </form>

                                <div className="existing-user white-bordered-rows">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="existing-name">Cooper, Sheldon
                                                <button type="button" className="tooltip-trigger" data-toggle="tooltip" data-placement="right" title="Username: sheldon.cooper">?</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="user-email">sheldon.cooper@nih.gov</div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="phone-number">(202) 555-1212</div>
                                        </div>
                                    </div>
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="MuiFormControl-root MuiTextField-root">
                                                    <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" htmlFor="user-role" id="user-role2-label">
                                                        Role
                                                    </label>
                                                    <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                        <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="user-role2">
                                                            <option value="" selected>Contact Specialist</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                        <img src="images/icon-arrow-down-light.svg" className="select-down-arrow" alt="select arrow"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="white-table-label">Document Permissions</div>
                                                <div className="documents-perm">
                                                    <div className="doc-perm">
                                                        <label className="MuiFormControlLabel-root">
                                                            <span className="MuiButtonBase-root MuiIconButton-root jss142 MuiCheckbox-root MuiCheckbox-colorPrimary jss143 Mui-checked MuiIconButton-colorPrimary" aria-disabled="false">
                                                                <span className="MuiIconButton-label">
                                                                    <input type="checkbox" checked="" className="jss145" name="checkedB" data-indeterminate="false" value=""/>
                                                                    <svg className="MuiSvgIcon-root" focusable="false" viewbox="0 0 24 24" aria-hidden="true">
                                                                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                                                                    </svg>
                                                                </span>
                                                                <span className="MuiTouchRipple-root"></span>
                                                            </span>
                                                            <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Technical PDF</span>
                                                        </label>
                                                    </div>
                                                    <div className="doc-perm">
                                                        <label className="MuiFormControlLabel-root">
                                                            <span className="MuiButtonBase-root MuiIconButton-root jss142 MuiCheckbox-root MuiCheckbox-colorPrimary jss143 Mui-checked MuiIconButton-colorPrimary" aria-disabled="false">
                                                                <span className="MuiIconButton-label">
                                                                    <input type="checkbox" checked="" className="jss145" name="checkedB" data-indeterminate="false" value=""/>
                                                                    <svg className="MuiSvgIcon-root" focusable="false" viewbox="0 0 24 24" aria-hidden="true">
                                                                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                                                                    </svg>
                                                                </span>
                                                                <span className="MuiTouchRipple-root"></span>
                                                            </span>
                                                            <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Business PDF</span>
                                                        </label>
                                                    </div>
                                                    <div className="doc-perm">
                                                        <label className="MuiFormControlLabel-root">
                                                            <span className="MuiButtonBase-root MuiIconButton-root jss142 MuiCheckbox-root MuiCheckbox-colorPrimary jss143 Mui-checked MuiIconButton-colorPrimary" aria-disabled="false">
                                                                <span className="MuiIconButton-label">
                                                                    <input type="checkbox" checked="" className="jss145" name="checkedB" data-indeterminate="false" value=""/>
                                                                    <svg className="MuiSvgIcon-root" focusable="false" viewbox="0 0 24 24" aria-hidden="true">
                                                                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                                                                    </svg>
                                                                </span>
                                                                <span className="MuiTouchRipple-root"></span>
                                                            </span>
                                                            <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Business Excel</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2"></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                <button type="button" className="MuiButtonBase-root MuiButton-root MuiButton-contained btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" className="MuiButtonBase-root MuiButton-root disabled-btn MuiButton-contained btn btn-primary">Add User</button>
              </div>
                        </div>
                    </div>
                    </div>
                
                </Fragment>
            
         );
    }
}

